<template>
  <div class="d-flex position-absolute position-bottom-0 position-right-0 pr-2 pb-2 font-medium-1 font-weight-bold text-primary">
    {{ version }}
  </div>
</template>

<script>
import version from '@/libs/version'

export default {
  name: 'Version',
  data() {
    return {
      version: version.number,
    }
  },
}
</script>

<style scoped>

</style>
