<template>
  <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
  >
    <template #button-content>
      <feather-icon
          icon="DatabaseIcon"
          size="21"
          style="color: rgba(98,207,98,0.8);"
      />
      <span class="ml-50 text-body">{{ company ? company.name_company : '' }}</span>
    </template>
    <b-dropdown-item
        v-for="localeObj in companies"
        :key="localeObj.id"
        @click="changeDB(localeObj.id)"
    >
      <feather-icon
          icon="DatabaseIcon"
          size="21"
          :style="{ color: styleClassDB(localeObj.id) ? 'rgba(98,207,98,0.8)' : 'black'}"
      />
      <span class="ml-50">{{ localeObj.name_company }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BNavItemDropdown, BDropdownItem} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      company: {},
    }
  },
  mounted() {
    this.company = useJwt.getCompanySelected()
  },
  methods: {
    styleClassDB(id) {
      const company = useJwt.getCompanySelected()

      if (company && +company.id === +id) {
        return true
      }

      return false
    },
    changeDB(database) {
      const company = this.companies.find(l => l.id === database)
      useJwt.setCompanySelected(company)
      this.company = company
      window.location.reload()
    },
  },
}
</script>

<style>

</style>
