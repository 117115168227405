export default [
  {
    title: 'Dashboard',
    route: 'main',
    icon: 'HomeIcon',
    permission: 'dashboard.menu',
  },
  {
    title: 'Clientes',
    route: 'customers',
    icon: 'UserCheckIcon',
    permission: 'customer.menu',
  },
  {
    title: 'Buscador avanzado',
    route: 'customers-search-advanced',
    icon: 'SearchIcon',
    permission: 'search.advanced.menu',
  },
  {
    title: 'Diseñador',
    icon: 'EditIcon',
    permission: 'passwords.menu',
    children: [
      {
        title: 'Marcas',
        route: 'designers-brands',
        permission: 'design.brand.menu',
      },
      {
        title: 'Dispositivos',
        route: 'designers-devices',
        permission: 'design.devices.menu',
      },
      {
        title: 'Accesos',
        route: 'designers-access',
        permission: 'design.access.menu',
      },
      {
        title: 'Remotos',
        route: 'designers-remotes',
        permission: 'design.remote.menu',
      },
      {
        title: 'Proveedores',
        route: 'designers-providers',
        permission: 'design.providers.menu',
      },
    ],
  },
  {
    title: 'Tickets',
    icon: 'CreditCardIcon',
    permission: 'ticket.menu',
    children: [
      {
        title: 'Todos',
        route: 'tickets',
        permission: 'ticket.index',
      },
      {
        title: 'En Proceso',
        route: 'tickets-in-progress',
        permission: 'ticket.index',
      },
      {
        title: 'Pendiente',
        route: 'tickets-on-hold',
        permission: 'ticket.index',
      },
      {
        title: 'Finalizado',
        route: 'tickets-finished',
        permission: 'ticket.index',
      },
    ],
  },
  {
    title: 'Taller',
    icon: 'ClipboardIcon',
    permission: 'workshop.menu',
    children: [
      {
        title: 'Ordenes',
        route: 'workshops-orders',
        permission: 'order.menu',
      },
      {
        title: 'Equipos',
        route: 'workshops-equipments',
        permission: 'equipment.menu',
      },
      {
        title: 'Marcas',
        route: 'workshops-brands',
        permission: 'brand.menu',
      },
      {
        title: 'Accesorios',
        route: 'workshops-accessories',
        permission: 'accessory.menu',
      },
    ],
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    permission: 'settings.index',
    children: [
      {
        title: 'Correo',
        route: 'settings-config',
      },
      {
        title: 'Categorías',
        route: 'settings-categories',
        permission: 'category.menu',
      },
      {
        title: 'Compañías',
        route: 'settings-companies',
        permission: 'company.menu',
      },
      {
        title: 'Roles',
        route: 'settings-roles',
        permission: 'roles.menu',
      },
      {
        title: 'Agentes',
        route: 'settings-agents',
        permission: 'agent.menu',
      },
      {
        title: 'Usuarios',
        route: 'settings-users',
        permission: 'users.menu',
      },
      {
        title: 'Path',
        route: 'settings-path',
        permission: 'company.path',
      },
    ],
  },
]
