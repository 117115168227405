<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <Database :companies="companies" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user && user.profile ? user.profile.full_name : '' }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="accessPermissions('users.update.profile')"
          link-class="d-flex align-items-center"
          :to="{ name: 'settings-profile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar Sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue'
import router from '@/router'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Database from '@/layouts/components/Database.vue'
import useJwt from '@/auth/jwt/useJwt'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  components: {
    Database,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {},
      companies: [],
    }
  },
  mounted() {
    if (window.location.href.indexOf('/company/tickets') === -1) {
      this.getMe()
    }
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async handleLogout() {
      await useJwt.logout().then(() => {
        const recaptchaInstance = this.$recaptchaInstance
        recaptchaInstance.showBadge()
        router.push({ name: 'login' })
      }).catch(error => this.errorResp(error))
    },
    async getMe() {
      await useJwt.me().then(({ data }) => {
        this.user = data
        useJwt.setCompanies(this.user.companies)
        const companySelected = useJwt.getCompanySelected()
        if (companySelected) {
          useJwt.setCompanySelected(companySelected)
        } else {
          useJwt.setCompanySelected(this.user.companies[0])
        }
        useJwt.setUserData(this.user)
        this.companies = this.user.companies
      }).catch(error => this.errorResp(error))
    },
  },
}
</script>
